import { useEffect } from 'react'

import { Metadata, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { BEFORE_LOGOUT } from 'src/graphql/users.graphql'

const LogoutPage = () => {
  const { loading, isAuthenticated, logOut } = useAuth()

  const [beforeLogout] = useMutation(BEFORE_LOGOUT, {
    onCompleted: async () => {
      await logOut({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    },
  })
  useEffect(() => {
    async function logoutFn() {
      if (!loading && isAuthenticated) {
        await beforeLogout()
      }
    }
    logoutFn()
  }, [loading, isAuthenticated, logOut])

  return (
    <>
      <Metadata title="Logout" description="Logout page" />
    </>
  )
}

export default LogoutPage
